import request from '@/utils/request'


// 查询用户关注列表
export function listFocus(query) {
  return request({
    url: '/user/focus/list',
    method: 'get',
    params: query
  })
}

// 查询用户关注分页
export function pageFocus(query) {
  return request({
    url: '/user/focus/page',
    method: 'get',
    params: query
  })
}

// 查询用户关注详细
export function getFocus(data) {
  return request({
    url: '/user/focus/detail',
    method: 'get',
    params: data
  })
}

// 新增用户关注
export function addFocus(data) {
  return request({
    url: '/user/focus/add',
    method: 'post',
    data: data
  })
}

// 修改用户关注
export function updateFocus(data) {
  return request({
    url: '/user/focus/edit',
    method: 'post',
    data: data
  })
}

// 删除用户关注
export function delFocus(data) {
  return request({
    url: '/user/focus/delete',
    method: 'post',
    data: data
  })
}
